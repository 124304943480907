<template>
    <!-- <img id="spfl" src="./assets/spfl.png"> -->
    <img id="spfl-logo" src="./assets/spfl-logo.svg" />
    <div id="home-color">
        <div id="home-name">
            <NameComponent v-bind:name="homeName"></NameComponent>
        </div>
    </div>
    <div :style="awayColorStyle" id="away-color">
        <div id="away-name">
            <NameComponent v-bind:name="awayName"></NameComponent>
        </div>
    </div>

    <div id="home-score-color">
        <div id="home-score">
            <ScoreComponent v-bind:score="homeScore"></ScoreComponent>
        </div>
    </div>

    <div id="away-score-color">
        <div id="away-score">
            <ScoreComponent v-bind:score="awayScore"></ScoreComponent>
        </div>
    </div>

    <div id="timer-color">
        <div id="timer">
            <TimerComponent
                v-bind:timer="timer"
                v-bind:timerActive="timerActive"
            />
        </div>
    </div>

    <div id="control-panel">
        <button v-on:click="homeScoreIncrease" class="control-button">
            {{ homeName }} ({{ homeScore }}) UP
        </button>
        <button v-on:click="awayScoreIncrease" class="control-button">
            {{ awayName }} ({{ awayScore }}) UP
        </button>

        <button v-on:click="startTimer" class="control-button">
            Start Timer
        </button>
        <button v-on:click="stopTimer" class="control-button">
            Stop Timer
        </button>
        <button v-on:click="setTimerMinutes" class="control-button">
            Set Timer
        </button>
        <button v-on:click="resetScore" class="control-button">
            Reset Score
        </button>

        <input class="control-input" v-model="awayName" />
        <input class="control-input" v-model="awayColor" />
        <input class="control-input" v-model="timerMinutes" type="number" />
    </div>
</template>

<script>
import ScoreComponent from "./components/ScoreComponent.vue";
import NameComponent from "./components/NameComponent.vue";
import TimerComponent from "./components/TimerComponent.vue";

export default {
    name: "App",
    components: {
        ScoreComponent,
        NameComponent,
        TimerComponent
    },
    data() {
        return {
            homeName: "ARB",
            awayName: "X",
            homeScore: 0,
            awayScore: 0,
            awayColor: "000000",
            timer: 0,
            timerActive: false,
            timerMinutes: 45
        };
    },
    // created() {
    //   const bc = new BroadcastChannel('test_channel');
    //   bc.onmessage = event => { this.homeName = event }
    // },
    computed: {
        awayColorStyle() {
            return {
                backgroundColor: `#${this.awayColor}`
            };
        }
    },
    mounted() {
        let urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has("awayName")) {
            this.awayName = urlParams.get("awayName").toUpperCase();
        }

        if (urlParams.has("awayColor")) {
            this.awayColor = urlParams.get("awayColor").toUpperCase();
        }
    },
    methods: {
        homeScoreIncrease() {
            this.homeScore++;
        },
        awayScoreIncrease() {
            this.awayScore++;
        },
        startTimer() {
            this.timerActive = true;
        },
        stopTimer() {
            this.timerActive = false;
        },
        setTimerMinutes() {
            this.timerActive = false;
            setTimeout(() => {
                this.timer = this.timerMinutes * 60;
            }, 1000);
        },
        resetScore() {
            this.homeScore = 0;
            this.awayScore = 0;
        }
    },
    watch: {
        timerActive(value) {
            if (value) {
                setTimeout(() => {
                    this.timer++;
                }, 1000);
            }
        },
        timer: {
            handler() {
                if (this.timerActive) {
                    setTimeout(() => {
                        this.timer++;
                    }, 1000);
                }
            },
            immediate: true
        }
    }
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#spfl-logo {
    position: absolute;
    background: white;
    border-radius: 5px 0 0 5px;
    padding: 5px 20px 5px 10px;
    left: 30px;
    top: 56px;
    height: 31px;
}

#home-color {
    position: absolute;
    left: 94px;
    top: 56px;
    border-radius: 5px 0 0 5px;
    background: #9f305b;
    width: 100px;
    height: 41px;
}

#home-name {
    color: white;
    font-size: 2em;
    text-align: center;
    vertical-align: middle;
    line-height: 41px;
}

#home-score-color {
    position: absolute;
    left: 194px;
    /* padding: 0 10px 0 10px; */
    top: 56px;
    background: #000000af;
    /* background: #283494; */
    width: 50px;
    height: 41px;
}

#home-score {
    color: white;
    font-size: 2em;
    text-align: center;
    vertical-align: middle;
    line-height: 41px;
}

#away-name {
    color: white;
    font-size: 2em;
    text-align: center;
    vertical-align: middle;
    line-height: 41px;
}

#away-color {
    position: absolute;
    left: 292px;
    top: 56px;
    border-radius: 0 5px 5px 0;
    /* background: #2b23f2; */
    background: #000000;
    /* background: #344ab3; */
    width: 100px;
    height: 41px;
}

#away-score-color {
    position: absolute;
    left: 242px;
    top: 56px;
    /* padding: 0 10px 0 0; */
    background: #000000af;
    width: 50px;
    height: 41px;
}

#away-score {
    color: white;
    font-size: 2em;
    text-align: center;
    vertical-align: middle;
    line-height: 41px;
}

#timer-color {
    position: absolute;
    left: 385px;
    top: 56px;
    border-radius: 0 5px 5px 0;
    padding: 0 15px 0 20px;
    z-index: -1;
    background: #000000af;
    width: 100px;
    height: 41px;
}

#timer {
    color: white;
    font-size: 2em;
    text-align: center;
    vertical-align: middle;
    line-height: 41px;
}

#spfl-home {
    position: absolute;
    left: 0;
    top: 0;
}

#spfl-away {
    position: absolute;
    left: 0;
    top: 0;
}

#control-panel {
    position: absolute;
    bottom: 0px;
}

#away-name-input {
    height: 100px;
    font-size: 2em;
}

#away-name-input {
    height: 100px;
    font-size: 2em;
}

.control-button {
    width: 200px;
    height: 100px;
    font-size: 2em;
}

.control-input {
    height: 100px;
    font-size: 2em;
}

body {
    margin: 0;
    background: #7089a2;
}
</style>
